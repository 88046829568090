
/* ================
 BAnner
================*/

.banner{
    padding:140px 0px;
    background:url("../images/banner/bg-2.jpg") center center no-repeat;
    background-size:cover;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        background: #000000;
        width: 100%;
        height: 100%;
        opacity: .6;
    }

    .banner-content{
        h1{
            font-size: 60px;
            margin: 15px 0px 15px;
            line-height: 73px;
            letter-spacing: -1.3px;
            color:$light;
        }
        p{
            margin-bottom:35px;
            color:$light;
            opacity:.8;
            font-size: 20px;
            line-height: 38px;
        }

        .subheading{
            color:$light;
            text-transform: capitalize;
            letter-spacing: 0px;
            font-size: 16px;
        }
       
    }
}


.banner-img-round {
    position: relative;
    z-index: 1;

    &:before{
        position: absolute;
        content:"";
        right:10px;
        top:0px;
        background:url("../images/bg/dot-bg.png")no-repeat;
        width:40%;
        height:40%;
        z-index: -1;
    }
    img{
        border-radius: 100%;
        overflow: hidden;
    }
}

/*-------------------------
Banner style-2 
-----------------------*/


.banner-2{
    background:#F5F6F8;
    padding-bottom:180px;
    .banner-content{
        p{
            margin-bottom:30px;
        }
    }
}



/*-------------------------
Banner style search 
-----------------------*/

.banner-3{
    padding:100px 0px 300px;
    position: relative;
    background:url("../images/banner/slider_image1.jpg") 100% 0% no-repeat;
    background-size: cover;
    overflow: hidden;

    .banner-content{
        h1{
            font-size: 60px;
            line-height: 70px;
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }
}

.form-search-banner {
	position: relative;
}
.form-search-banner .form-control {
	height: 70px;
    border-color: rgba(255, 255, 255, 0.2);
    background:#fff;
    border-radius: 5px;
    padding:0px 20px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
}
.form-search-banner .btn {
	position: absolute;
	right: 6px;
	top: 10px;
}
.banner-cat {
	color: #222;
	text-transform: capitalize;
	font-size: 13px;
	margin-top: 30px;
}
.banner-cat span {
	display: inline-block;
	padding: 2px 15px;
	border-radius: 5px;
	background: #fff;
}

.banner-4 {
    h1{
        font-size: 80px;
        line-height: 90px;
    }
    p{
        font-size: 18px;
        padding:0px 60px
    }
}