.footer-2{
    background: #1D292E;
    padding:80px 0px 0px;

    .widget-title{
        margin-bottom:30px;
        color:$light;
    }

    .footer-links{
        color:#adb4b7;
        li{
            margin:10px 0px;
        }
        a{
            color:#adb4b7;
            text-decoration: underline;
            text-decoration-color: transparent;
            &:hover{
                color: $light;
                padding-left:10px;
                text-decoration-color: $light;
            }
        }
    }

    .footer-btm{
        margin-top: 60px;
        border-top-color: rgba(107, 110, 111,.2);
        a{
            color:#adb4b7;
            &:hover{
                color: $light;
            }
        }
        p{
            color:$light;
        }
    
    }
}

.text-gray{
    color:#6b6e6f ;
}

.footer-about{
    color:$light;
    p{
        color:$light;
    }

    .footer-info{
        color:#adb4b7;
        margin-top: 20px;
        span{
            color:$light;
            margin-right:5px;
            font-family: $primary-font;
        }
    }

    .footer-socials{
        margin-top:30px;
        a{
            color:$light;
        }
    }
    
}


.footer-logo{
    img{
        width:125px;
    }
}

.fixed-btm-top {
	position: fixed;
	bottom: 40px;
    right: 40px;
    opacity: 0;
    visibility: hidden;
}

.fixed-btm-top .scroll-to-top {
	display: inline-block;
	text-align: center;
	height: 50px;
	background: $primary-color;
	color: #fff;
	width: 50px;
	border-radius: 100%;
	padding-top: 10px;
}

.fixed-btm-top.reveal {
	opacity: 1;
	visibility: visible;
}