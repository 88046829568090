
$primary-font: 'Kumbh Sans', sans-serif;
$secondary-font:'Questrial', sans-serif;

$font-color :#535967;
// $heading-color: #263b5e;
$heading-color: #3f3a64;

$light: #fff;
$primary-color:#20ad96;
// $secondary-color: #07294D;
$secondary-color: #385777;

$black: #222;
$pure-black: #000;
$border-color:#eee;

$gray : #F4F7FC;
$light-gray: #eee;




