
/* ================
Features
================*/

.feature-item{
    text-align: center;
    transition:.4s;
    margin-bottom:20px;
    
    h4{
        margin-bottom:10px;
        transition:.4s;
    }

    &:hover{
        .feature-icon{
            transform: translateY(-10px);
        }
        h4{
            color:$primary-color;
        }
    }
}

.feature-icon{
    font-size: 70px;
    line-height: 70px;
    margin-bottom: 25px;
    color:$primary-color;
    transition:.4s;
}

.feature-2{
    padding-bottom:70px;
}

.feature-4{
    margin-top:-70px;
    position: relative;
    .feature-item{
        background:$light;
        padding:40px 30px;
        border-radius: 5px;
        border:1px dashed #ddd;
    }
}


.feature-style-2{
    text-align: left;
    margin-bottom:40px;
    .feature-icon{
        float: left;
        font-size: 30px;
        line-height: 60px;
        width: 60px;
        height: 60px;
        text-align: center;
        background:#F4F5F8;
        border-radius: 5px;
    }

    &:hover{
        .feature-icon{
            background:$primary-color;
            color:$light;
            border-color: $primary-color;
        }
    }
    .feature-text{
        padding-left: 30px;
        overflow: hidden;
        h4{
            font-size: 20px;
        }
    }
    a{
        color:#1D292E;
        font-size: 14px;
        &:hover{
            color:$primary-color;
        }
    }
}


/*========================
Course category Section
============================*/

.cat-1{
    background: url("../images/course/course1.jpg") center center no-repeat;
    background-size: cover;
}
.cat-2{
    background: url("../images/course/course2.jpg") center center no-repeat;
    background-size: cover;
}
.cat-3{
    background: url("../images/course/course3.jpg") center center no-repeat;
    background-size: cover;
}
.cat-4{
    background: url("../images/course/course4.jpg") center center no-repeat;
    background-size: cover;
}


.single-course-category{
    padding:60px 25px 50px;
    text-align: center;
    margin-bottom:30px;
    border-radius: 10px;
    overflow: hidden;
    transition: all .4s ease 0s;
    position: relative;
    z-index: 1;

    &:before{
        position: absolute;
        content:"";
        left:0px;
        top:0px;
        width:100%;
        height:100%;
        background:rgba(29, 41, 46,.8);
        z-index: -1;
    }

    h4{
        font-size: 22px;
        margin-bottom: 0px;
        a{
            color: $light;
            &:hover{
                color: $primary-color;
            }
        }
    }

    p{
        margin-bottom: 0px;
        color: $light;
    }

    &:hover{
        box-shadow: 0 40px 60px rgba(0,0,0,.08);
        border-color:transparent;
    }
}



/*========================
 Course category style2
============================*/

.category-imgbox{
    position: relative;
    margin-bottom:30px;
    .thumbnail-img{
        position: relative;
        border-radius:10px;
        overflow: hidden;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: -webkit-gradient(linear,left top,left bottom,color-stop(43%,transparent),to(#000));
            background: -webkit-linear-gradient(top,transparent 43%,#000 100%);
            background: -o-linear-gradient(top,transparent 43%,#000 100%);
            background: linear-gradient(180deg,transparent 43%,#000 100%);
            -webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
            -o-transition: all .25s cubic-bezier(.645,.045,.355,1);
            transition: all .25s cubic-bezier(.645,.045,.355,1);
        }
    }
    img{
        border-radius:10px;
        width:100%;
    }
    .category-content{
        position: absolute;
        content:"";
        left:0px;
        bottom:0px;
        padding:20px;

        h5{
            font-size: 22px;
        }

        h5,p{
            margin-bottom: 0px;
            color:$light;
        }
    }
}

/*========================
 Contact Section
============================*/

// .contact-info{
//     .contact-item{
//         text-align: center;
//         p{
//             margin-bottom: 10px;
//             color:$primary-color;
//         }
//         img{
//             max-width: 60px;
//             max-height: 60px;
//             margin-bottom:20px;
//         }
//     }
// }
    


// .contact-heading{
//     text-align: center;
//     padding-top:100px;
//     margin-bottom:40px;
// }

// .contact__form{
//     .form-control{
//         height:50px;
//         border-radius: 46px;
//         padding-left: 20px;
//         border:2px solid #eee;
//         &:hover{
//             border-color:$primary-color;
//         }
//     }

//     .form-group{
//         margin-bottom: 25px;
//     }
//     textarea.form-control{
//         height:auto;
//         border-radius: 15px;
//         padding-top:15px;
//     }

//     label{
//         text-transform: uppercase;
//         font-size: 14px;
//         font-weight: 700;
//         letter-spacing: 1px;
//         color:$secondary-color;
//     }
// }




/*=================
Popular OCurse 2
==================*/
.popular-course-2{
    padding-top:200px;
}

/*=================
Course Block
==================*/

.course-block{
    background:$light;
    transition: .4s;
    margin-bottom:30px;
    &:hover{
        border-color:transparent;
        box-shadow: 0 0 30px rgba(0,0,0,0.08);
    }
    .course-content{
        padding:20px 30px 30px;

        h4{
            margin:10px 0px;
        }
        p{
            margin-bottom: 0px;
        }
    }

    .course-cat{
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 2px;
        color:$primary-color;
        font-weight: 600;
    }
}

.course-img{
    position:relative;
    margin-bottom:10px;
    overflow: hidden;
    border-radius: 5px;
    img{
        width:100%;
    }
    .course-price {
        position: absolute;
        content: "";
        background: $primary-color;
        font-weight: 700;
        color:$secondary-color;
        color:$light;
        text-align: center;
        font-size: 26px;
        font-family: $primary-font;
        bottom: -10px;
        right: 0px;
        padding: 10px 20px;
        border-radius: 15px 0px 0px 0px;
    }
     .course-price2 {
        position: absolute;
        content: "";
        background: $primary-color;
        font-weight: 700;
        color: #385777;
        color: $light;
        top: 0px;
        right: 20px;
        text-align: center;
        font-size: 26px;
        padding: 10px 20px;
        font-family: $primary-font;
    }
   
}


.course-meta {
    span {
        margin-right: 15px;
        display: inline-block;
        font-size: 14px;
        color:#696969;
        i{
            margin-right:7px;
        }
    }
    .course-label{
        text-transform: uppercase;
        color:$primary-color;
        font-weight: 600;
        background:#F7F5F4; 
        padding:0px 6px;
    }
}


.course-price{
    color:$primary-color;
    font-weight:700;
    font-size: 30px;
    margin-bottom: 10px;
    font-family: $primary-font;

    .del{
        text-decoration: line-through;
        color:#727695;
        font-weight: 400;
        font-size: 20px;
    }
}

/* Rating */

.rating{
    margin-bottom:10px;
    font-size: 13px;

    i{
        color:#f2b827;
    }

    span{
        color:#222;
        margin-left: 10px;
    }
}


.course-list-item {
	padding: 20px;
    margin-bottom: 20px;
    border:0px;
    border:1px dashed #ddd;
    border-radius:5px;
    .course-content{
        padding: 0px;
        p{
            margin-bottom:0px;
        }
    }

    del{
        font-size: 18px;
        color:#ccc;
    }
    
}
.course-list-item .course-img {
    margin-bottom:0px;
    img {
        width:100%;
        border-radius: 100%;
    }
}





.course-style-4{
    border-radius:5px;
    overflow: hidden;
    .course-duration{
        i{
            margin-right: 5px;
        }
    }
    h4{
        font-size: 22px;
    }
    .course-price{
        margin-top:25px;
        font-size: 40px;
    }
}




/*========================
Course Filter Tab
==========================*/
.course-filter {
	margin: 0;
	padding: 0;
	list-style: none;
    margin-bottom:50px;
}
.course-filter li {
    display: inline-block;
    margin:0px 5px;
}
.course-filter li a {
	letter-spacing: 1px;
	text-transform: capitalize;
    color: #414141;
    border-bottom:1px solid transparent;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 5px;
}
.course-filter li a:hover, .course-filter li.active a, .f-link li a:hover, .f-link li.active a {
    color:$light;
    border-color:$primary-color;
    background:$primary-color;
}

.course.gutter .course-item {
	padding: 10px;
}




/*========================
 Funfact  Counter
==========================*/

.counter-item{
    i{
        font-size: 60px;
        line-height: 60px;
    }
    .count{
        margin:30px 0px 10px
    }
   .counter {
        font-size: 60px;
        margin-top: 30px;
        line-height: 50px;
        color: $primary-color;
        font-family: $primary-font;
        font-weight: 600;
    }
    
    p{
        margin-bottom: 0px;
    }

    h6{
        text-transform: capitalize;
    }
}

.counter-inner{
    padding:40px 20px;
    border-radius: 15px;

    .counter-item{
        color:$light;
        border-right:1px solid rgba(255,255,255,.04);
        h6{
            color:$light;
        }
    }
}

.counter-block-2{
    z-index: 2;
}

/* ====================
About Section
======================*/

.about-section{
    .section-heading{
        margin-bottom:40px;
        h3{
            span{
                display: block;
            }
        }
    }
}
.about-text-block{
    clear: both;
    margin-bottom: 40px;
    .icon-box{
        color:$primary-color;  
        width:85px;
        height:85px;
        text-align: center;
        background: #F7F5F4;
        font-size: 40px;
        margin-right: 30px;
        padding-top: 28px;
        border-radius: 100%;
        float: left;
        position: relative;

        &:before{
            position: absolute;
            content:"";
            left:0px;
            right:0px;
            margin: 0px auto;
            bottom:-50px;
            width:1px;
            height:100%;
            background:#F7F5F4;
            z-index: -1;
        }

       &.border-none:before{
            display: none;
        }
    }
   

    h4{
        font-size: 20px;
    }

    p{
        margin-bottom: 0px;
    }

    .about-desc{
        overflow: hidden;
    }
}

.about-img{
    padding-left: 30px;
}


.about-features{
    margin-bottom:40px;
    li{
        margin:20px 0px;
       i{
           float: left;
           margin-right: 20px;
           overflow: hidden;
       }
    }
}


.btn-link{
    color:#222;
}

.about{
    position: relative;
    &:before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 60%;
        height: 100%;
        background: #F5F6F8;
    }


    .about-text-block .icon-box{
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
        background:$light;
    }
}








.about-imgbox{
    margin-bottom:30px;
    img{
        width:100%;
        border-radius:15px;
    }
}








/* ====================
VIDEO SECTION
======================*/


.video-block{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    img{
        width:100%;
    }
    
    &:before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background:rgba(7, 41, 77,.4);
    }
 
}

.video-icon{
    display: inline-block;
    width: 100px;
    height: 100px;
    z-index: 3;
    text-align: center;
    padding-top: 36px;
    font-size: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: $primary-color;
    border-radius: 100%;
    color: $light;

    &::after, &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        bottom: 0;
        left: 0;
        border-radius: 100%;
        border: 1px solid $light;
    }
    &::before {
        animation: ripple 2s linear infinite;
    }
    &::after {
        animation: ripple 2s linear 1s infinite;
    }
    &:hover {
        background-color: $primary-color;
    }
}


@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}



/* ====================
Testimonial
======================*/
.testimonial{
    background:#EBF4F8;
}

.px-120{
    padding:0px 120px;
}

.testimonials-slides .owl-dots .owl-dot span {
	width: 7px;
	height: 7px;
	margin: 5px 7px;
	display: block;
}
.testimonials-slides  .owl-dots .owl-dot.active span{
    width: 10px;
	height: 10px;
} 
.testimonials-slides  .owl-dots .owl-dot.active span, 
.testimonials-slides  .owl-dots .owl-dot:hover span {
    background:$primary-color;
    opacity: 1;
    outline: none;
}

.testimonials-slides .owl-item.active.center .testimonial-item{
    opacity: 1;
}
.testimonials-slides {
    .testimonial-item{
        opacity: .3;
    }
}
.testimonial-item{
    padding:60px;
    margin-bottom:30px;
    background:$light;
    border-radius: 10px;
    position: relative;

    i{
        position: absolute;
        content:"";
        right:60px;
        top:40px;
        font-size: 60px;
        color:#EBF4F8;
    }

    .client-info{
        img{
            width: 70px;
            border-radius: 50%;
        }

        .testionial-author{
            color:$primary-color;
            margin-top: 15px;
            margin-bottom: 20px;
            font-weight: 600;
            font-family: $primary-font;
        }
    }

    .testimonial-info-desc{
        font-family: $primary-font;
        color:#222;
        margin-top: 20px;
    }
}

.testimonials-slides-2,.testimonials-slides-3{
    .testimonial-item{
        padding:60px;
        margin-bottom:20px;
        background:#F4F5F8;
        // background: #FDFDFD;
        opacity: 1;

        i{
            color:$primary-color;
        }
    }
}

.testimonial-style-2{
    &.testimonial-item{
        i{
            position:static;
            font-size: 60px;
            color:$primary-color;
            margin-bottom:20px;
        }
    
    }
    .client-info{
        margin-top: 30px;
        display: flex;
        align-items: center;
       
        .client-img{
            margin-right: 20px;
            img{
                box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
                border:2px solid $light;
            }
        }
    }
}


/*====================
 CLient logo Section
=====================*/

.clients{
    &:hover{
        .client-logo{
            transition: all .4s ease 0s;
            opacity: .3;
            &:hover{
                opacity: 1;
            }
        }
    }

    .client-logo{
        border:1px solid #ddd;
        padding:20px 10px;
        border-radius: 5px;
    }
}

.clients-2{
    &:hover{
        .client-logo{
            transition: all .4s ease 0s;
            opacity: .3;
            &:hover{
                opacity: 1;
            }
        }
    }
    .client-logo{
        padding:20px 10px;
        border-radius: 5px;
        background:#F4F5F8;
    }
}

/* ====================
Footer Section
======================*/

.footer{
    background:#EBF4F8;
    padding:80px 0px 0px;
    position: relative;
    .footer-widget{
        .widget-title{
            margin-bottom:20px;
            font-size: 22px;
        }
       
    }
    
    .footer-links{
        li{
            padding: 5px 0px;
            font-size: 15px;
            color:#222;
        }
        a{
            color:#222;
            &:hover{
                color:$primary-color;
            }
        }
    }

    .footer-socials{
        margin-top:30px;
        a{
            font-size: 14px;
            opacity: .8;
            margin-right: 13px;
            color:#222;

            &:hover{
                color:$primary-color;
            }
        }
    }
}

.footer-btm{
    margin-top: 70px;
    padding:20px 0px 20px;
    border-top:1px solid rgba(0,0,0,.1);

    p{
        margin-bottom:0px;
    }
    
    .footer-contact{
        margin-bottom:0px;
        a{
            color:#000;
            font-family: $primary-font;
            margin-left: 10px;
            &:hover{
                color:$primary-color;
            }
        }
    }
}




/*==========================
Susbribe Section
=======================*/
.subscribe-form{
    position: relative;

    a{
        position: absolute;
        content:"";
        right:5px;
        top:5px;
    }
    .form-control{
        height:65px;
        padding-left: 20px;
        background:$light;
        border-color:#ddd;

        &:focus{
            border-color: $primary-color;
        }
    }

}
.form-inner{
    background:#F5F6F8;
    padding:40px;
}




/*==========================
Team section
=======================*/
.team-item{
    text-align: center;
    transition: all .4s ease 0s;
    border-radius: 5px;
    overflow: hidden;
    img{
        width: 100%;
    }

    &:hover{
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
        .team-socials{
            opacity: 1;
            transform: translateY(10px);
        }
        .team-info{
            transform: translateY(-20px);
        }
    }
}

.team-info{
    position: relative;
    background:#fff;
    margin:-50px 20px 0px;
    padding:20px 10px;
    border-radius: 5px;
    transition: all .4s ease 0s;
    overflow: hidden;
    
    h5{
        margin-bottom:0px;
        font-size: 20px;
    }
    p{
        margin-bottom:10px;
    }

    .team-socials{
        transition: all .4s ease 0s;
        transform: translateY(80px);
        a{
            width:35px;
            height:35px;
            text-align: center;
            border:1px solid #ddd;
            display: inline-block;
            border-radius: 100%;
            color: #222;
            padding-top: 3px;
            font-size: 14px;
            background: #ddd;
            &:hover{
                background-color: $primary-color;
                color: $light;
                border-color:$primary-color;
            }
        }
    }
}




/*==========================
Course Page
=======================*/


.course-top-wrap{
    margin-bottom:50px;
}
.topbar-search{
    position:relative;

    .form-control{
        height: 50px;
        border-radius: 30px;
        padding-left: 20px;
        background:#eef5f9;
        border-color:#eef5f9;
    }

    label{
        position: absolute;
        right: 20px;
        top: 11px;
    }
}


/*==========================
cONTACT pAGE
=======================*/

.contact__form .form-control {
	height: 55px;
	border-radius: 46px;
	padding-left: 20px;
	border: 2px solid #eee;
	background: #eee;
}
.contact__form textarea.form-control {
	height: auto;
	border-radius: 15px;
	padding-top: 15px;
}
.contact-item {
	margin-bottom: 20px;
	border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-right: 30px;
    h4{
        font-size: 20px;
    }
    p{
        margin-bottom: 7px;
    }
}


#map{
    height: 400px;
    margin-top: 70px;
}

/*============
 Error Page
==============*/
.error-page .error-header h2 {
    font-size: 90px;
    line-height: 1.1;
    font-weight: 700;
  }
  
  .error-page .error-message {
    margin: 15px 0px;
  }
  
  .error-page .error-content .btn {
    margin-top: 30px;
  }
  